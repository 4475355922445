import * as React from "react"
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import {Avatar, Container, Grid, Typography} from "@mui/material";

import ammonPhoto from "../../images/about-us/ammon.png";
import jeffreyPhoto from "../../images/about-us/jeffrey.png";


// markup
const AboutUsPage = () => {
    return (
        <Layout title={"About Us"}>
            <main>
                <Hero title={"About Us"}
                      subtitle={"The experience of a trial attorney with the expertise of a tech executive"}/>

                <Container style={{paddingTop: "3em", marginBottom: "3em"}}>
                    <Typography variant={"h4"} textAlign={'center'} color={"Primary"}>Founders</Typography>
                    <Grid container spacing={10} marginTop={3}>
                        <Grid item md={6}>
                            <Grid container marginBottom={2}>
                                <Grid item xs={4}>
                                    <Avatar src={ammonPhoto} sx={{height: 100, width: 100}} alt={"photo of Ammon Hepworth"} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant={"h5"}>Ammon Hepworth</Typography>
                                    <Typography variant={"subtitle2"}>Co-founder</Typography>
                                </Grid>
                            </Grid>
                            <Typography>Ammon has been a leader in three previous tech start-ups, varying from early to late stage. He has over 10 years of software development experience, including 5 years of leading and managing technical teams.</Typography>
                            <br/>
                            <Typography>His unique mix of product, software, and people management experience have given him the expertise to build great products and teams. He is known for his compassionate leadership style and his passion for developing products that people love. </Typography>
                            <br/>
                            <Typography>Ammon holds a PhD in Engineering from Brigham Young University.</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Grid container marginBottom={2}>
                                <Grid item xs={4}>
                                    <Avatar src={jeffreyPhoto} sx={{height: 100, width: 100}} alt={"photo of Jeffrey Castleton"} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant={"h5"}>Jeffrey Castleton</Typography>
                                    <Typography variant={"subtitle2"}>Co-founder</Typography>
                                </Grid>
                            </Grid>
                            <Typography>Before becoming an attorney, Jeff worked in the enterprise software industry. After a few years in tech, Jeff decided to shift gears and attend law school. </Typography>
                            <br/>
                            <Typography>Jeff is now a trial lawyer and practices in the areas of civil litigation and criminal defense. During one of his trials, Jeff became frustrated with the cumbersome and inefficient "sticky note" method of selecting the jury. Following the trial he approached Ammon about the idea that has now become Jurybox.</Typography>
                            <br/>
                            <Typography>Jeff holds a JD from San Joaquin College of Law.</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </Layout>
    );
};

export default AboutUsPage;
